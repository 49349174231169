import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";


Vue.config.productionTip = false;
Vue.prototype.$baseURL = "http://127.0.0.1:8000/api",

  new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
