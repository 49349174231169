import http from "../../services/https";
export default {
  actions: {
    getLoggedStudent(context) {
      return new Promise((resolve, reject) => {
        http()
          .get("/student/roles/getProfile")
          .then((response) => {
            context.commit("updateUserState", response.data.data);
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getLoggedTeacher(context) {
      return new Promise((resolve, reject) => {
        http()
          .get("/teacher/roles/getProfile")
          .then((response) => {
            context.commit("updateUserState", response.data.data);
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    authenticateCred(context, data) {
      return new Promise((resolve, reject) => {
        http()
          .post("/admin/auth/login", data)
          .then((response) => {
            localStorage.setItem("token", response.data.token);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    authenticateTeacherCred(context, data) {
      return new Promise((resolve, reject) => {
        http()
          .post("/teacher/auth/login", data)
          .then((response) => {
            localStorage.setItem("token", response.data.token);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    authenticateStudentCred(context, data) {
      return new Promise((resolve, reject) => {
        http()
          .post("/student/auth/login", data)
          .then((response) => {
            localStorage.setItem("token", response.data.token);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    logOutTeacher(context) {
      return new Promise((resolve, reject) => {
        http()
          .post("/teacher/auth/logout")
          .then((response) => {
            localStorage.removeItem("token");
            context.commit("updateUserState", null);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    logOutStudent(context) {
      return new Promise((resolve, reject) => {
        http()
          .post("/student/auth/logout")
          .then((response) => {
            localStorage.removeItem("token");
            context.commit("updateUserState", null);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    isToken() {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem("token");
        if (token) {
          resolve(token);
        } else {
          const err = new Error("couldn't find authentication token");
          reject(err);
        }
      });
    },
  },
  mutations: {
    updateUserState(state, data) {
      state.user = data;
    },
    updateToken(state, data) {
      state.isAuthenticated = data;
    },
    updateLoginState(state, data) {
      state.user = data;
    },
  },
  state: {
    user: [],
    isAuthenticated: localStorage.getItem("token") || "",
    host: "https://backend.hijcn.education/api/v1/public",
    // host: "http://127.0.0.1:8000/api/v1/public",
  },
  getters: {
    isAuthenticated: (state) => !!state.isAuthenticated,
    user: (state) => state.user,
    host: (state) => state.host,
  },
};
