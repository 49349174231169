import Vue from "vue";
import VueRouter from "vue-router";
// import { isAuth } from "../middleware/auth";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/home/HomePage.vue"),
  },

  /* end of auth routes */
  {
    path: "/admin-login",
    name: "home",
    component: () => import("@/views/admin/LoginPage.vue"),
  },
  {
    path: "/teachers-login",
    name: "home",
    component: () => import("@/views/Teachers/LoginPage.vue"),
  },
  {
    path: "/students-login",
    name: "home",
    component: () => import("@/views/Students/LoginPage.vue"),
  },

  /* end of auth routes */

  /* admin route */
  {
    path: "/admin",
    name: "AdminHome",
    component: () => import("@/views/admin/AdminView.vue"),
    children: [
      {
        path: "",
        name: "home",
        component: () => import("@/views/admin/HomePage.vue"),
      },
      {
        path: "create-teacher",
        name: "createTeacher",
        component: () => import("@/views/admin/CreateTeacher.vue"),
      },
      {
        path: "add-subjects",
        name: "addSubject",
        component: () => import("@/views/admin/AddSubjects.vue"),
      },
      {
        path: "manage-subjects",
        name: "manageSubject",
        component: () => import("@/views/admin/ManageSubjects.vue"),
      },
      {
        path: "manage-teacher",
        name: "manageTeacher",
        component: () => import("@/views/admin/ManageTeacher.vue"),
      },
      {
        path: "create-sessions",
        name: "createSession",
        component: () => import("@/views/admin/CreateSession.vue"),
      },
      {
        path: "create-classes",
        name: "createClass",
        component: () => import("@/views/admin/CreateClass.vue"),
      },
      {
        path: "manage-classes",
        name: "manageClass",
        component: () => import("@/views/admin/ManageClasses.vue"),
      },
      {
        path: "create-students",
        name: "createStudent",
        component: () => import("@/views/admin/CreateStudents.vue"),
      },
      {
        path: "manage-students",
        name: "manageStudent",
        component: () => import("@/views/admin/ManageStudents.vue"),
      },
      {
        path: "create-exam",
        name: "createExam",
        component: () => import("@/views/admin/ManageExams.vue"),
      },
      {
        path: "publish-results",
        name: "publishResults",
        component: () => import("@/views/admin/publishResults"),
      },
      {
        path: "generate-card",
        name: "generateCard",
        component: () => import("@/views/admin/GenerateCard.vue"),
      },
      {
        path: "view-result",
        name: "viewResult",
        component: () => import("@/views/admin/ViewResult.vue"),
      },
      {
        path: "view-annual-result",
        name: "viewAnnual",
        component: () => import("@/views/admin/viewAnnual.vue"),
      },
      {
        path: "view-promotion-sheet",
        name: "viewPromotionSheet",
        component: () => import("@/views/admin/ViewPromotionSheet.vue"),
      }
    ],
  },

  /* end of admin route*/

  /**teacher routes */
  {
    path: "/teacher",
    name: "TeacherHome",
    component: () => import("@/views/Teachers/TeacherView.vue"),
    children: [
      {
        path: "",
        name: "teacherHome",
        component: () => import("@/views/Teachers/HomePage.vue"),
      },
      {
        path: "upload-result",
        name: "uploadResult",
        component: () => import("@/views/Teachers/UploadResult.vue"),
      },
      {
        path: "teacher-profile",
        name: "teacherProfile",
        component: () => import("@/views/Teachers/TeacherProfile.vue"),
      },
      {
        path: "upload-assignments",
        name: "uploadAssignment",
        component: () => import("@/views/Teachers/UploadAssignments.vue"),
      },
      {
        path: "add-comments",
        name: "addComments",
        component: () => import("@/views/Teachers/AddComments.vue"),
      },
    ],
  },
  /**teacher routes */
  {
    path: "/student",
    name: "StudentHome",
    component: () => import("@/views/Students/StudentView.vue"),
    children: [
      {
        path: "",
        name: "StudentHome",
        component: () => import("@/views/Students/HomePage.vue"),
      },
      {
        path: "upload-result",
        name: "uploadResult",
        component: () => import("@/views/Teachers/UploadResult.vue"),
      },
      {
        path: "student-profile",
        name: "teacherProfile",
        component: () => import("@/views/Students/StudentProfile.vue"),
      },
      {
        path: "study-material",
        name: "studyMaterial",
        component: () => import("@/views/Students/StudyMaterial.vue"),
      },
      {
        path: "view-comment",
        name: "viewComment",
        component: () => import("@/views/Students/viewComment.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
