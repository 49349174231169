import axios from "axios";
const BaseApi = axios.create({
  // baseURL: "http://127.0.0.1:8000/api/v1",
  baseURL: "https://backend.hijcn.education/api/v1/",
});
const Http = () => {
  let token = localStorage.getItem("token");
  if (token) {
    BaseApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    BaseApi.defaults.timeout = 100000;
  }
  return BaseApi;
};
export default Http;
